import React from "react";
import { StaticImage } from "gatsby-plugin-image";

let props = {
  alt: "media",
  objectPosition: "50% 50%",
  loading: "lazy",
  placeholder: "blurred",
  width: 120,
  height: 80,
};

export const MEDIA_LIST = {
  "May 2021": [
    {
      href:
        "https://news.bloomberglaw.com/privacy-and-data-security/china-continues-crackdown-on-personal-data-collection-via-apps",
      img: <StaticImage {...props} src="../images/media/media72.jpg" />,
      title: "China Continues Crackdown on Personal Data Collection Via Apps",
      text:
        "Top internet regulatory authorities in China have issued draft interim regulations to govern what personal data private companies collect through mobile applications, in a continued crackdown on the volume of such collection.",
      site: "Bloomberg Law",
    },
  ],
  "April 2021": [
    {
      href:
        "https://www.scmp.com/tech/big-tech/article/3130395/chinese-advertisers-brace-sea-change-under-apples-new-privacy-rules",
      img: <StaticImage {...props} src="../images/media/media71.jpg" />,
      title: "Small advertisers wary of new iPhone ad tracking rules",
      text:
        "As Apple prepares to enforce its new system, experts say the Chinese government’s crackdown on data privacy violations gives reluctant industry players a substantial motive to re-examine their business practices.",
      site: "South China Morning Post",
    },
  ],
  "March 2021": [
    {
      href: "https://www.verdict.co.uk/apple-china-privacy/",
      img: <StaticImage {...props} src="../images/media/media70.jpg" />,
      title:
        "War in your hip pocket as Apple battles Big China on iPhone privacy",
      text:
        "Tech giants in China are actively challenging Apple's new privacy policy by testing a tool designed to enable tracking without users' consent.",
      site: "Verdict",
    },
    {
      href:
        "https://techcrunch.com/2021/03/22/china-regulation-data-collection-may-starts/",
      img: <StaticImage {...props} src="../images/media/media69.jpg" />,
      title:
        "China to ban apps from collecting excessive user data starting May 1",
      text:
        "Starting May 1, apps in China can no longer force users into providing excessive personal data, according to a document jointly released by a group...",
      site: "TechCrunch",
    },
    {
      href: "https://amp.ft.com/content/520ccdae-202f-45f9-a516-5cbe08361c34",
      img: <StaticImage {...props} src="../images/media/media68.jpeg" />,
      title: "China’s tech giants test way around Apple’s new privacy rules",
      text:
        "ByteDance and Tencent see if they can keep tracking iPhone users with solution created by state-backed group",
      site: "Financial Times",
    },
  ],
  "February 2021": [
    {
      href:
        "https://www.voguebusiness.com/technology/we-chat-mini-games-burberry-dior-livestreaming-monetisation",
      img: <StaticImage {...props} src="../images/media/media67.jpg" />,
      title: "Can luxury brands crack WeChat mini games in 2021?",
      text:
        "WeChat mini games are a fun way for luxury brands to engage with 500 million Chinese consumers. Could they offer even more?",
      site: "Vogue Business",
    },
    {
      href:
        "https://fortune.com/2021/02/08/clubhouse-app-in-china-censors-ban/",
      img: <StaticImage {...props} src="../images/media/media66.jpg" />,
      title:
        "Clubhouse creates ‘once-in a lifetime’ chance to breach China’s Great Firewall. But when will it be banned?",
      text:
        "The conversations are freewheeling for now, but censors are likely listening.",
      site: "Fortune",
    },
  ],
  "January 2021": [
    {
      href:
        "https://technosports.co.in/2021/01/03/apple-removed-39000-games-including-46000-apps-from-the-china-store-to-meet-the-deadline/",
      img: <StaticImage {...props} src="../images/media/media65.jpg" />,
      title:
        "Apple removed 39,000 games including 46,000 apps from the China Store to meet the deadline",
      text:
        "Apple removed a huge number of game apps from its China store on Thursday. The number is 39,000, which is the biggest removal ever in one day.",
      site: "TechnoSports",
    },
  ],
  "December 2020": [
    {
      href:
        "https://www.straitstimes.com/asia/east-asia/apple-removes-39000-game-apps-from-china-store-to-meet-deadline",
      img: <StaticImage {...props} src="../images/media/media64.jpg" />,
      title:
        "Apple removes 39,000 game apps from China store to meet deadline for publishers to get licence",
      text:
        "On Dec. 2, Apple sent an email to the remaining developers who own unlicensed games on App Store China",
      site: "The Straits Times",
    },
    {
      href:
        "https://www.wsj.com/articles/apples-china-app-store-sheds-games-under-pressure-11608719400",
      img: <StaticImage {...props} src="../images/media/media63.jpeg" />,
      title:
        "Apple’s China App Store Sheds Videogames as Beijing Tightens Internet Control",
      text:
        "As Beijing steps up policing of content and censorship, the company is booting thousands of apps from the platform, illustrating its vulnerability to state pressure.",
      site: "WSJ",
    },
  ],
  "November 2020": [
    {
      href:
        "https://pandaily.com/apple-to-remove-remaining-unlicensed-ios-games-from-chinese-market-by-jan-1/",
      img: <StaticImage {...props} src="../images/media/media62.jpg" />,
      title:
        "Apple to Remove Remaining Unlicensed iOS Games from Chinese Market by Jan. 1",
      text:
        "On Dec. 2, Apple sent an email to the remaining developers who own unlicensed games on App Store China",
      site: "Pandaily",
    },
    {
      href:
        "https://www.thatsmags.com/beijing/post/31891/rich-bishop-on-china-s-ever-changing-app-marketplace",
      img: <StaticImage {...props} src="../images/media/media61.jpeg" />,
      title: "Rich Bishop on China's Ever-changing App Marketplace",
      text:
        "AppInChina CEO Rich Bishop leads a team that helps global brands launch in China.",
      site: "That's Online",
    },
  ],
  "October 2020": [
    {
      href:
        "https://www.cnbc.com/2020/10/12/chinese-app-that-let-users-access-google-facebook-has-disappeared.html",
      img: <StaticImage {...props} src="../images/media/media60.jpeg" />,
      title:
        "An app that let Chinese users bypass the Great Firewall and access Google, Facebook has disappeared",
      text:
        "An app that briefly gave Chinese internet users access to foreign websites such as YouTube and Facebook — services that have long been blocked — has now disappeared.",
      site: "CNBC",
    },
    {
      href:
        "https://finance.yahoo.com/news/chinese-app-provides-legal-way-064351345.html",
      img: <StaticImage {...props} src="../images/media/media59.jpg" />,
      title: "Chinese App Helps Users Bypass Great Firewall - Then Disappears",
      text:
        "One Chinese app briefly gave the country’s internet users access to long-banned websites like Facebook Inc. and Google, setting off speculation about the future of Beijing’s censorship practices.",
      site: "Yahoo Finance",
    },
  ],
  "September 2020": [
    {
      href:
        "https://technode.com/2020/09/23/huaweis-harmony-os-a-third-major-mobile-operating-system/",
      img: <StaticImage {...props} src="../images/media/media58.jpg" />,
      title: "Huawei's Harmony OS: a third major mobile operating system?",
      text:
        "With Huawei increasingly cut off from the America-driven tech world, it's betting on the new Harmony OS to build a new mobile ecosystem.",
      site: "TechNode",
    },
    {
      href: "https://www.ft.com/content/04b2be3a-e71d-4a04-a9f7-8c6e04210ad9",
      img: <StaticImage {...props} src="../images/media/media57.jpeg" />,
      title: "Huawei courts app makers despite sanctions threat to its devices",
      text:
        "Chinese tech group urges developers to continue working with it as rivals encroach on smartphone market share",
      site: "Financial Times",
    },
  ],
  "August 2020": [
    {
      href:
        "https://www.telegraph.co.uk/technology/2020/08/03/microsoft-buying-tiktok-would-upend-tech-industry-facebook/",
      img: <StaticImage {...props} src="../images/media/media56.jpeg" />,
      title: "'Boring' Microsoft could become TikTok's unlikely saviour",
      text:
        "If the sale does go through, it would cause one of the most momentous shake-ups to the tech landscape in years.",
      site: "The Telegraph",
    },
    {
      href:
        "https://ydraft.com/apple-deleted-thousands-of-unlicensed-apps-from-china-store-report/",
      img: <StaticImage {...props} src="../images/media/media55.jpg" />,
      title:
        "Apple deleted thousands of unlicensed apps from China store: Report",
      text:
        "It seems that Apple is taking new measures with respect to third-party applications, now aimed at the field of video games.",
      site: "YDRAFT",
    },
    {
      href:
        "https://www.financial-world.org/news/news/business/6166/californian-iphone-maker-apple-removes-thousands-of-game-app-from-store/",
      img: <StaticImage {...props} src="../images/media/media54.jpg" />,
      title:
        "Californian iPhone maker Apple removes thousands of game app from store",
      text:
        "On Saturday, data from Qimai had revealed that the iPhone maker Apple Inc. had removed over 26,000 Chinese apps from its app store since end-June",
      site: "Financial World",
    },
    {
      href:
        "https://www.thestatesman.com/technology/apples-china-ops-at-risk-govt-to-plug-existing-loopholes-1502918263.html",
      img: <StaticImage {...props} src="../images/media/media53.jpg" />,
      title: "Apple’s China ops at risk, govt to plug existing loopholes",
      text:
        "Fuelling the tensions between the US and China, Apple’s Chinese operations, that include millions of Apple customers and much of the company’s",
      site: "The Statesman",
    },
    {
      href:
        "https://techcrunch.com/2020/08/03/chinese-netizens-criticize-bytedance-ceo/",
      img: <StaticImage {...props} src="../images/media/media52.jpg" />,
      title:
        "Chinese internet users brand ByteDance CEO a ‘traitor’ as TikTok seeks US buyer",
      text:
        "ByteDance is not backing down from its ambitions to become a global technology powerhouse, even as TikTok loses its largest market...",
      site: "TechCrunch",
    },
  ],
  "July 2020": [
    {
      href:
        "https://www.scmp.com/tech/apps-social/article/3092953/apple-leaves-foreign-developers-scrambling-unlicensed-games",
      img: <StaticImage {...props} src="../images/media/media51.jpg" />,
      title:
        "Apple leaves foreign developers scrambling as unlicensed games removed from China App Store",
      text:
        "A total of 2,394 games had been removed by Apple from its China App Store on July 4, after more than a thousand each day were taken down from July 1.",
      site: "South China Morning Post",
    },
    {
      href:
        "https://www.telegraph.co.uk/technology/2020/07/01/apple-could-remove-21000-games-chinese-app-store/",
      img: <StaticImage {...props} src="../images/media/media50.jpg" />,
      title: "Apple could remove 21,000 games from Chinese App Store",
      text:
        "Apple now requires game developers to submit evidence of their licence to operate each game in China",
      site: "The Telegraph",
    },
    {
      href: "https://www.ft.com/content/976fb2cb-3325-478b-82b4-aed030da0041",
      img: <StaticImage {...props} src="../images/media/media49.jpg" />,
      title:
        "Apple freezes updates for thousands of mobile games on China App Store",
      text:
        "Tech group under pressure from Chinese government to comply with local rules.",
      site: "Financial Times",
    },
  ],
  "June 2020": [
    {
      href:
        "https://www.bloomberg.com/news/articles/2020-06-22/apple-set-to-nix-thousands-of-unlicensed-iphone-games-in-china",
      img: <StaticImage {...props} src="../images/media/media48.jpg" />,
      title: "Apple Will Remove Thousands of Unlicensed iPhone Games in China",
      text:
        "Apple Inc. will start removing thousands of mobile games lacking government approval from its App Store in China next month, closing a loophole...",
      site: "Bloomberg News",
    },
    {
      href:
        "https://arstechnica.com/tech-policy/2020/06/apple-caters-to-china-by-pulling-thousands-of-unlicensed-iphone-games/",
      img: <StaticImage {...props} src="../images/media/media47.jpg" />,
      title:
        "Apple caters to China by pulling thousands of “unlicensed” iPhone games",
      text:
        "Paid iPhone games need licenses from Chinese gov't to stay on Apple App Store.",
      site: "Ars Technica",
    },
  ],
  "April 2020": [
    {
      href:
        "https://www.scmp.com/tech/apps-social/article/3078087/surge-downloads-amid-coronavirus-will-see-consumer-app-spending",
      img: <StaticImage {...props} src="../images/media/media46.jpeg" />,
      title:
        "Coronavirus effect will see consumer app spending double over five years",
      text:
        "China will lead the mobile app market through to 2024 but Beijing’s strict regulatory environment creates uncertainty for developers, says report",
      site: "South China Morning Post",
    },
    {
      href:
        "https://www.scmp.com/tech/apps-social/article/3074525/20000-ios-games-could-be-removed-china-report-says",
      img: <StaticImage {...props} src="../images/media/media45.jpeg" />,
      title: "20,000 iOS games could be removed in China, report says",
      text:
        "An AppInChina report says a third of China’s paid games on the iOS App Store won’t have a license before a key deadline",
      site: "South China Morning Post",
    },
  ],
  "March 2020": [
    {
      href: "https://www.cultofmac.com/691651/apple-app-store-china/",
      img: <StaticImage {...props} src="../images/media/media44.jpg" />,
      title: "China crackdown could boot thousands of iOS games from App Store",
      text:
        "Thousands of games in the Apple App Store China are in danger of getting booted due to new government license enforcement efforts.",
      site: "Cult of Mac",
    },
    {
      href:
        "https://www.abacusnews.com/games/20000-ios-games-could-be-removed-china-report-says/article/3074322",
      img: <StaticImage {...props} src="../images/media/media43.jpeg" />,
      title: "20,000 iOS games could be removed in China, report says",
      text:
        "An AppInChina report says a third of China’s paid games on the iOS App Store won’t have a license before a key deadline",
      site: "Abacus News",
    },
    {
      href:
        "https://techcrunch.com/2020/03/01/china-roundup-apple-closes-app-store-loophole/",
      img: <StaticImage {...props} src="../images/media/media42.jpg" />,
      title: "China Roundup: Apple closes a 4-year-old App Store loophole",
      text:
        "This week, Apple made some major moves that are telling of its increasingly compliant behavior in China where it has seen escalating competition...",
      site: "TechCrunch",
    },
  ],
  "February 2020": [
    {
      img: <StaticImage {...props} src="../images/media/media41.jpg" />,
      title: "Licenze di gioco: Apple controllerà tutte le licenze in Cina",
      text:
        "Apple ha deciso di controllare le licenze di gioco rilasciate dal governo cinese per i titoli a pagamento o con acquisti in-app.",
      site: "iPhone Italia",
      href:
        "https://www.iphoneitalia.com/726309/apple-controlla-licenze-gioco-cina",
    },
    {
      img: <StaticImage {...props} src="../images/media/media40.jpg" />,
      title: "App Store Chinois : Apple vérifiera toutes les licences de jeux",
      text:
        "Les développeurs sont préoccupés par la décision soudaine d'Apple de commencer à contrôler les licences de jeux de l'App Store délivrées...",
      site: "iPhonote",
      href:
        "https://www.iphonote.com/actu/157027/app-store-chinois-apple-verifiera-toutes-les-licences-de-jeux",
    },
    {
      img: <StaticImage {...props} src="../images/media/media39.png" />,
      title:
        "Apple to Require Licenses for Games in China App Store | China Film Insider",
      text:
        "Apple has sent a notice to developers requiring them to submit license numbers for paid games before Jun. 30 if they want to distribute in mainland China.",
      site: "China Film Insider",
      href:
        "http://chinafilminsider.com/apple-to-require-licenses-for-games-in-china-app-store/",
    },
    {
      img: <StaticImage {...props} src="../images/media/media38.jpeg" />,
      title: "Apple requires Chinese games to get government approval",
      text:
        "Apple reportedly asked for-pay Chinese game developers to prove they have government approval for their applications.",
      site: "Cult of Mac",
      href:
        "https://www.cultofmac.com/687699/apple-requires-chinese-games-to-get-government-approval/",
    },
    {
      img: <StaticImage {...props} src="../images/media/media37.jpg" />,
      title: "Apple's China game license check will pinch indie developers",
      text:
        "Developers are concerned over Apple's sudden decision to start checking game licenses issued by the Chinese government for titles with in-app purchases.",
      site: "AppleInsider",
      href:
        "https://appleinsider.com/articles/20/02/27/apples-china-game-license-check-will-pinch-indie-developers",
    },
    {
      img: <StaticImage {...props} src="../images/media/media36.png" />,
      title:
        "Why Apple’s new rules for games in China could spell trouble for indies",
      text:
        "Apple and TikTok maker ByteDance are asking for developers to show game licenses as China tightens its grip on video game publishing.",
      site: "Abacus News",
      href:
        "https://www.abacusnews.com/games/why-apples-new-rules-games-china-could-spell-trouble-indies/article/3052518",
    },
    {
      img: <StaticImage {...props} src="../images/media/media35.png" />,
      title:
        "Apple sets deadline for games apps to comply with Chinese law as government tightens grip on gaming",
      text:
        "The regulation, introduced by China in 2016, requires mobile game makers wishing to have in-app purchases or charge for their content...",
      site: "CNBC",
      href:
        "https://www.cnbc.com/2020/02/27/apple-sets-deadline-for-gaming-apps-to-comply-with-chinese-law.html",
    },
  ],
  "July 2019": [
    {
      img: <StaticImage {...props} src="../images/media/media34.jpg" />,
      title: "Tencent Wants a Bigger Cut of Game Sales",
      text:
        "Tencent Holdings Ltd. is pressing China’s top smartphone vendors and app stores to boost the cut of revenue it gets from games sold through their platforms, people familiar with the matter said...",
      site: "Bloomberg News",
      href:
        "https://www.bloomberg.com/news/articles/2019-07-11/tencent-presses-chinese-app-stores-for-bigger-cut-of-game-sales",
    },
  ],
  "May 2019": [
    {
      img: <StaticImage {...props} src="../images/media/media33.jpg" />,
      title:
        "Huawei privé d'Android : le géant chinois peut-il se passer de Google?",
      text:
        "Le géant chinois ne va plus pouvoir utiliser les services phares (YouTube, Gmail, etc.) du groupe de Mountain View.",
      site: "Les Echos",
      href:
        "https://www.lesechos.fr/tech-medias/hightech/android-huawei-peut-il-se-passer-de-google-1022503",
    },
    {
      img: <StaticImage {...props} src="../images/media/media32.png" />,
      title: "Descriptive List Of App Stores For 2019",
      text:
        "After immense research, we have compiled a collection of the best alternative app stores for Android and iOS for app publishers to consider",
      site: "MobileAppDaily",
      href: "https://www.mobileappdaily.com/app-stores-list",
    },
  ],
  "February 2019": [
    {
      img: <StaticImage {...props} src="../images/media/media31.jpg" />,
      title: "Why I'm Raising My Bet On Tencent",
      text:
        "China’s severe restriction on new video games will continue this year. However, Tencent's old/current titles are still among the world’s top-grossing games. Ten",
      site: "Seeking Alpha",
      href: "https://seekingalpha.com/article/4244475-raising-bet-tencent",
    },
  ],
  "August 2018": [
    {
      img: <StaticImage {...props} src="../images/media/media30.jpeg" />,
      title:
        "Apple Removes Lottery Apps After State Media Rebuke, But Domestic Stores Do Not",
      text:
        "CCTV singling out U.S. firm reflects a ‘double standard’ as problem is widespread among local stores, analyst says",
      site: "Caixin Global",
      href:
        "https://www.caixinglobal.com/2018-08-21/apple-removes-lottery-apps-after-state-media-rebuke-but-domestic-stores-do-not-101317373.html?cxg=web&amp;Sfrom=twitter",
    },
    {
      img: <StaticImage {...props} src="../images/media/media29.jpg" />,
      title: "Is Your City China's Silicon Valley?",
      text:
        "According to 2018 China City Business Appeal Ranking by Yicai Media Group’s big data department The Rising Lab, Shanghai, Beijing, Shenzhen and Guangzhou are the 4 cities most attractive to businesses...",
      site: "Chinaccelerator 中国加速",
      href: "https://chinaccelerator.com/is-your-city-chinas-silicon-valley/",
    },
    {
      img: <StaticImage {...props} src="../images/media/media28.jpg" />,
      title: "Top 10 Android app stores in China in Q2 2018",
      text:
        "Many of the app stores hold the same or similar presence over the last 90 days.",
      site: "TechNode",
      href: "https://technode.com/2018/08/13/android-china-q2-2018/",
    },
  ],
  "June 2018": [
    {
      img: <StaticImage {...props} src="../images/media/media27.jpeg" />,
      title:
        "How China’s economic reforms made way for Zhongguancun – China’s Silicon Valley - to become a transnational innovation hub",
      text:
        "It’s a spectacular success story: What once was a deserted graveyard for eunuchs during the Qing Dynasty (1644-1911 ) is now the cradle for China’s tech giants, unicorns and start-ups.",
      site: "Global Times",
      href: "http://www.globaltimes.cn/content/1108701.shtml",
    },
    {
      img: <StaticImage {...props} src="../images/media/media26.jpeg" />,
      title: "Mobile App Stores Guide 2018",
      text:
        "The Google Play Store for Android and the Apple App Store for iOS are the two largest distribution channels for mobile apps, offering almost global coverage to a huge potential audience.",
      site: "Business of Apps",
      href: "http://www.businessofapps.com/guide/mobile-app-stores-list/",
    },
    {
      img: <StaticImage {...props} src="../images/media/media25.jpeg" />,
      title: "Google Quietly Files Back Into China",
      text:
        "Roll-out of ‘Files Go’ app marks latest baby step in internet giant’s slow return to mainland market",
      site: "Caixin Global",
      href:
        "https://www.caixinglobal.com/2018-06-01/google-quietly-files-back-into-china-101261350.html",
    },
  ],
  "May 2018": [
    {
      img: <StaticImage {...props} src="../images/media/media24.jpg" />,
      title:
        "Ready for a blockchain smartphone future? Why the world needs Zippie",
      text:
        "At the end of 2006, just 6 months before the launch of the first iPhone, fewer than 20 million people on the planet owned a smartphone. If…",
      site: "Medium",
      href:
        "https://medium.com/zippie/ready-for-a-blockchain-smartphone-future-why-the-world-needs-zippie-644c215c6f89",
    },
    {
      img: <StaticImage {...props} src="../images/media/media23.jpg" />,
      title: "Top 10 Android app stores in China in Q1 2018",
      text:
        "While Chinese smartphone suppliers have reported a decrease in sales for the first time, the country’s internet user base continues to grow, with around 772 million internet users (97% smartphone users) at last count.",
      site: "TechNode",
      href: "https://technode.com/2018/05/04/android-app-stores-q1-2018/",
    },
  ],
  "April 2018": [
    {
      img: <StaticImage {...props} src="../images/media/media22.jpg" />,
      title:
        "Apps in China survive longer on Apple than Android app stores... for now",
      text:
        "When it comes to removing apps, Apple’s China App Store seems more resistant to state criticisms than Android stores, but this might be just for now.",
      site: "TechNode",
      href: "https://technode.com/2018/04/12/ios-apps-china/",
    },
  ],
  "December 2017": [
    {
      img: <StaticImage {...props} src="../images/media/media21.jpg" />,
      title: "How to Sell EdTech in China - Product, Market and Investment ",
      text:
        "EdTech in China is on a surge. The government will invest $30 bn in STEM, AI, language learning and project-based edtech products by the year 2020.",
      site: "The EduTech Post",
      href:
        "http://www.edutechpost.com/edtech-china-product-market-investment/",
    },
  ],
  "October 2017": [
    {
      img: <StaticImage {...props} src="../images/media/media20.jpeg" />,
      title: "How to go global with your app via localization",
      text:
        "Localization can make your app go places Today, being global means you go local.",
      site: "AppFutura",
      href:
        "https://www.appfutura.com/blog/how-to-go-global-with-your-app-via-localization/",
    },
  ],
  "September 2017": [
    {
      img: <StaticImage {...props} src="../images/media/media19.jpg" />,
      title:
        "While Tech Giants Struggle to Enter Chinese App Market, Google Makes Investment for the Future ",
      text:
        "Third-party app markets like Tencent’s Myapp and Baidu’s Mobile Assistant continue to dominate the Chinese app market, while tech giants like Google, Facebook and Apple are nowhere in sight.",
      site: "The Sociable",
      href:
        "https://sociable.co/technology/tech-giants-struggle-chinese-app-market/",
    },
  ],
  "August 2017": [
    {
      img: <StaticImage {...props} src="../images/media/media18.jpg" />,
      title: "Deciphering China’s VPN Ban",
      text:
        "This weekend Apple took a dispiriting step in the policing of its Chinese mainland App store: the company removed several Virtual Private Network (VPN) applications that allowed users to...",
      site: "Electronic Frontier Foundation",
      href: "https://www.eff.org/deeplinks/2017/08/deciphering-chinas-vpn-ban",
    },
  ],
  "July 2017": [
    {
      img: <StaticImage {...props} src="../images/media/media17.jpg" />,
      title:
        "Scramble to download VPN apps as they vanished from Apple's China App Store",
      text:
        "People frantically downloaded the apps, as news rippled through social media.",
      site: "Mashable",
      href:
        "https://mashable.com/2017/07/31/china-vpn-app-ban/?europe=true#hf37zTI0CsqX",
    },
  ],
  "March 2017": [
    {
      img: <StaticImage {...props} src="../images/media/media16.jpg" />,
      title:
        "Gamesauce: Global Inspiration for Game Developers | Shlomo Freund: The Challenge of Apps in China",
      text:
        "Shlomo Freund is the founder of AppInChina. They work on the company’s general strategy and marketing focus, in addition to general product management.",
      site: "Casual Connect Video",
      href:
        "http://www.gamesauce.biz/2017/03/12/shlomo-freund-challenge-apps-china/",
    },
  ],
  "January 2017": [
    {
      img: <StaticImage {...props} src="../images/media/media15.jpg" />,
      title: "A Gap in the Great Firewall: Mobile Apps - January 2017s ",
      text:
        "Despite Beijing’s stance on regulating mobile app stores, the environment and fragmentation of the market makes it almost impossible to enforce the new measures.",
      site: "The Epoch Times",
      href:
        "https://www.theepochtimes.com/a-gap-in-the-great-firewall-mobile-apps_2213101.html",
    },
    {
      img: <StaticImage {...props} src="../images/media/media14.jpg" />,
      title:
        "Another brick in the Great Firewall: China begins censoring mobile apps",
      text:
        "China is now trying to exercise extreme oversight over another channel of digital media content -- mobile applications...",
      site: "Phone Arena",
      href:
        "https://www.phonearena.com/news/Another-brick-in-the-Great-Firewall-China-begins-censoring-mobile-apps_id90028",
    },
  ],
  "December 2016": [
    {
      img: <StaticImage {...props} src="../images/media/media13.jpg" />,
      title: "A Close Look at Copycatting in China's Game Market ",
      text:
        "China's mobile game ecosystem is infamous for its culture of copycatting. In this story, we unpack the main reasons behind copycatting in China.",
      site: "Chartboost",
      href:
        "http://www.chartboost.com/blog/2016/12/seeing-double-a-closer-look-at-copycatting-in-chinas-mobile-game-market/",
    },
  ],
  "November 2016": [
    {
      img: <StaticImage {...props} src="../images/media/media12.jpg" />,
      title: "How to Grow Your Mobile Game Business in China",
      text:
        "Learn how you can grow your mobile game business in China with this FREE guide from Chartboost!",
      site: "Chartboost",
      href:
        "https://www.chartboost.com/powerup-reports/china-mobile-games-marketing-nov-2016/",
    },
  ],
  "August 2016": [
    {
      img: <StaticImage {...props} src="../images/media/media11.jpg" />,
      title:
        "Five Rules Of App Localization In China: Money, Dating And App Store",
      text:
        "I recently sat down with Rock Zhang, a Chinese mobile entrepreneur. Rock is my classmate from business school, and we have both worked in the mobile industry for a while.",
      site: "Smashing Magazine",
      href:
        "https://www.smashingmagazine.com/2016/08/five-rules-app-localization-china-money-dating-app-store/",
    },
  ],
  "March 2016": [
    {
      img: <StaticImage {...props} src="../images/media/media10.jpg" />,
      title: "Feelter wins GMIC Tel Aviv with e-commerce social scoring",
      text:
        "The first ever winner of GMIC Tel Aviv's G-Startup pitch competition, Feelter says it impacts e-commerce by aggregating a range of opinions from across the web.",
      site: "Geektime",
      href:
        "http://www.geektime.com/2016/03/23/feelter-wins-gmic-tel-aviv-with-e-commerce-social-scoring/",
    },
  ],
  "January 2016": [
    {
      img: <StaticImage {...props} src="../images/media/media9.jpg" />,
      title:
        "Year-old Android 5 Lollipop on 33% of Google Play users, but only 20% of devices in China",
      text:
        "Google's ability to distribute new Android updates has always lagged far behind Apple's iOS, but the situation is even worse in China, where the  Lollipop version 5.x, introduced in 2014 alongside iOS 8...",
      site: "Apple Insider",
      href:
        "http://appleinsider.com/articles/16/01/14/year-old-android-5-lollipop-on-33-of-google-play-users-but-only-20-of-devices-in-china-",
    },
  ],
  "November 2015": [
    {
      img: <StaticImage {...props} src="../images/media/media8.jpg" />,
      title: "Google to Pursue Chinese App Market with Google Play Launch",
      text:
        "China is a hot region for app development and use, and Google is looking to re-establish itself there.",
      site: "Investing News",
      href:
        "https://investingnews.com/daily/tech-investing/mobile-web-investing/apple-google-play-china/",
    },
  ],
  "November 2014": [
    {
      img: <StaticImage {...props} src="../images/media/media7.jpg" />,
      title: "Google Play may be coming to China",
      text:
        "For those in major markets like the United States, we tend to think of Android as a Google-powered OS that is centered around the search giant’s services like Maps, Google Play and many others.",
      site: "Android Authority",
      href: "https://www.androidauthority.com/china-google-play-568324/",
    },
  ],
  "August 2014": [
    {
      img: <StaticImage {...props} src="../images/media/media6.jpg" />,
      title:
        "Startup Nation, The Book That Brought Israeli Fuzz in China Tech Industry",
      text:
        "There’s a craze for Israel in China tech industry. Chinese tech entrepreneurs admire Israelis’ fighting spirit and what they have achieved in technology. It’s largely thanks to the Startup Nation, a book about entrepreneurship in Israel.",
      site: "TechNode",
      href: "https://technode.com/2014/08/12/saul-singer/",
    },
  ],
  "June 2014": [
    {
      img: <StaticImage {...props} src="../images/media/media5.jpg" />,
      title:
        "How Some Of America's Biggest Tech Companies Are Expanding Into China",
      text:
        "As mobile internet usage continues to explode in China, here's how many American tech companies are entering the market.",
      site: "Business Insider",
      href:
        "https://www.businessinsider.com/us-tech-companies-expanding-into-china-2014-6?IR=T",
    },
    {
      img: <StaticImage {...props} src="../images/media/media4.jpg" />,
      title: "借力移动互联网 美国互联网公司再掀入华高潮",
      text:
        "As mobile internet usage continues to explode in China, here's how many American tech companies are entering the market.",
      site: "腾讯科技",
      href: "http://tech.qq.com/a/20140604/008803.htm",
    },
  ],
  "February 2014": [
    {
      img: <StaticImage {...props} src="../images/media/media3.jpg" />,
      title:
        "Google Play is NOT the place to be in China, ‘App in China’ connects you to Top 20 Chinese Android App Stores",
      text:
        "China is the biggest mobile market in the world in terms of subscribers which no mobile app developer with global ambitions can choose to ignore. Statistics from November 2013 put the total number of ...",
      site: "Your Story",
      href:
        "https://yourstory.com/2014/02/google-play-place-china-app-china-connects-top-20-chinese-android-app-stores/",
    },
  ],
  "November 2013": [
    {
      img: <StaticImage {...props} src="../images/media/media2.jpg" />,
      title: "Posts about AppInChina written by zacinshanghai",
      text: "",
      site: "China App Marketing",
      href: "https://chinaappmarketing.com/tag/appinchina/",
    },
  ],
  "August 2013": [
    {
      img: <StaticImage {...props} src="../images/media/media1.jpg" />,
      title: "25 startups that caught our eye",
      text:
        "Here’s our newest round-up of the featured startups on our site this week.",
      site: "Tech in Asia",
      href: "https://www.techinasia.com/asian-startups-list-25-august-2013",
    },
    {
      img: <StaticImage {...props} src="../images/media/media1_A.jpeg" />,
      title:
        "This new startup connects developers with China’s multitude of Android app stores",
      text:
        "China looks set to have 300 million active Android smartphone users by the end of the year, which makes it a market that app developers badly want to tap.",
      site: "Tech in Asia",
      href:
        "https://www.techinasia.com/appinchina-distributes-apps-to-top-china-android-app-stores",
    },
  ],
};
