import React, { useState } from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import { MEDIA_LIST } from "src/utils/media";
import icon from "images/svg_icons/foreign.svg";

const MonthBlock = ({ title, children }) => (
  <div className={classes.monthBlock}>
    <h2 id={title}>{title}</h2>
    {children}
  </div>
);

const MediaBlock = ({ href, img, title, text, site }) => (
  <div className={classes.mediaBlock}>
    <a href={href} target="_blank" rel="noopener noreferrer">
      {img}
      <div className={classes.text}>
        <h3>
          {site} <img src={icon} alt={site} />
        </h3>
        <h4>{title}</h4>
      </div>
    </a>
    <p className={classes.gray}>{text}</p>
  </div>
);

let filterItems = (filter, list) => {
  return filter
    ? {
        [filter]: list[filter],
      }
    : list;
};

export default () => {
  let [selectedDate, setSelectedDate] = useState("");
  const LIST = filterItems(selectedDate, MEDIA_LIST);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.filter}>
          <Layout>
            <label htmlFor="filter">Filter By Month:</label>
            <select
              name="filter"
              onChange={(e) => setSelectedDate(e.target.value)}
              value={selectedDate}
            >
              <option value="">Not Selected</option>
              {Object.keys(MEDIA_LIST).map((it, index) => (
                <option value={it} key={index}>
                  {it}
                </option>
              ))}
            </select>
          </Layout>
        </div>
        <Layout>
          <div className={classes.content}>
            {Object.keys(LIST).map((key) => (
              <MonthBlock key={key} title={key}>
                {MEDIA_LIST[key].map((it, index) => (
                  <MediaBlock key={index} {...it} />
                ))}
              </MonthBlock>
            ))}
          </div>
        </Layout>
      </div>
    </>
  );
};
