import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import MediaItems from "components/media/MediaItems";
import icon from "images/svg_icons/newspaper.svg";

export default () => (
  <>
    <Meta url="/media/" />
    <Nav />
    <LargeTitle
      size="small"
      img={icon}
      green="AppInChina"
      black="In The Top Media Outlets"
      text="Major news outlets regularly cite AppInChina for the most current data and insights into trends regarding the Chinese mobile app market."
    />
    <MediaItems />
    <CTA />
    <Footer />
  </>
);
